/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";

/* Generated theme by Material Theme Generator */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "@angular/material/theming";

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons+Sharp";

/* .mat-drawer-container */
.mat-drawer-container {
	height: 100%;
	width: 100%;
	background: var(--white);
	color: var(--grey1);

	.main-section {
		height: calc(100% - 64px);
		padding: 30px 20px 20px;

		@media screen and (max-width: 991px) {
			padding: 25px 15px 15px;
		}
	}

	// &.loginpage {
	// 	height: 100%;

	// 	.main-section {
	// 		height: 100%;
	// 	}
	// }


}


/* check-ul   */
.check-ul {
	li {
		position: relative;
		list-style: none;
		padding-left: 30px;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 20px;

		&::before {
			content: "";
			position: absolute;
			width: 18px;
			height: 14px;
			top: 3px;
			left: 0;
			background: url("/assets/images/list/tick.svg") no-repeat center center;
		}
	}
}

/* mat-form-field-type-mat-input  */
.mat-form-field {
	width: 100%;
}

.mat-form-field-type-mat-input {
	width: 100%;

	&.mat-form-field-appearance-outline {
		min-height: 43px;

		.mat-form-field-outline-thick {

			.mat-form-field-outline-start,
			.mat-form-field-outline-end,
			.mat-form-field-outline-gap {
				border-width: 1px;
			}
		}

		.mat-form-field-wrapper {
			padding: 0 !important;
			margin: 0;
		}

		.mat-form-field-outline {
			color: transparent;
			//height: 32px; (changes on on 05/02/2024)
			border: 1px solid var(--grey14);
			border-radius: 4px;
		}

		.mat-form-field-infix {
			padding: 9px 0 9px;
			margin: 0;
			color: var(--grey2);
			border: none;
			height: 32px;

			&::placeholder {
				color: var(--grey2);
			}
		}

		.mat-form-field-subscript-wrapper {
			padding: 0 !important;
			// margin-top: 23px;
			.mat-error{
			color:#FF341D
			}
		}

		.mat-datepicker-toggle {
			top: 4px;
			right: -8px;
			position: relative;
		}
	}
}

/* mat-form-field-type-mat-select */
.mat-form-field-type-mat-select,
.mat-form-field-type-mat-native-select {
	&.mat-form-field-appearance-outline {
		padding: 0;

		.mat-form-field-outline-thick {

			.mat-form-field-outline-start,
			.mat-form-field-outline-end,
			.mat-form-field-outline-gap {
				border-width: 1px;
			}
		}

		.mat-form-field-outline {
			color: var(--grey14);
			height: 32px;
		}

		.mat-form-field-infix {
			border: none;
			height: 32px;
			padding-top: 9px;
		}

		.mat-form-field-label {
			padding-top: 6px;
			color: var(--grey1);
			font-weight: 400;
			display: none !important;
		}

		.mat-form-field-wrapper {
			margin: 0 !important;
			overflow: hidden;
			height: 40px;
		}

		.mat-form-field-outline-gap {
			display: none;
		}

		.mat-input-element {
			padding: 0;
			margin: 4px 0 0;
			height: 40px;
			border: none;
		}

		.mat-select-arrow-wrapper {
			background: url("/assets/images/icons/down-arrow.svg") no-repeat center center;
			width: 15px;
			height: 15px;
			transform: none;
			display: inline-block;

			.mat-select-arrow {
				display: none;
			}
		}
	}

	&.ng-dirty {
		.mat-select-trigger {
			img {
				height: 18px;
			}
		}
	}
}

.fullwidth-checkbox {
	.mat-checkbox-layout {
		white-space: normal;
	}
}

/* button */
button {
	box-shadow: none !important;

	&.mat-primary {
		background-color: var(--orange1) !important;
		height: 40px;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		border-radius: 4px;
		text-transform: capitalize;
		box-shadow: none !important;
			color: var(--white) !important;

		&:focus {
			background-color: var(--orange1) !important;
		}

		&.mat-button-disabled {
			color: var(--white) !important;
			cursor: not-allowed;
		}
	}

	&.mat-stroked-button {
		height: 32px;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		border-radius: 4px;
		text-transform: capitalize;
		border-color: var(--orange1) !important;
	}

	&.btn-height-32 {
		height: 32px;
	}

	&.btn-style-1 {
		width: 88px;
		height: 32px;
		line-height: normal;

		&:last-child {
			margin-left: 16px;
		}
	}

	&.btn-style-2 {
		width: 128px;
		height: 32px;
		line-height: normal;
	}

	&.btn-style-4 {
		height: 40px;
		line-height: normal;
	}

	&.btn-style-5 {
		height: 50px;
		line-height: normal;
	}
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
	background-color: var(--orange1) !important;

	&:focus,
	&.cdk-focused {
		background-color: var(--orange1) !important;

		.mat-button-focus-overlay {
			opacity: 0;
		}
	}
}

/* toggle switch */
.mat-slide-toggle-thumb {
	background: #ada9a8 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--orange1) !important;
}

.mat-slide-toggle-bar {
	background: var(--grey11) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
	background-color: var(--green2) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--orange1) !important;
}
.edit-img {
	cursor: pointer;
}

/*  checkbox */
.mat-checkbox-frame {
	border: 0.3px solid var(--grey6);
}

/* dropdown-links */
.dropdown-links {
	margin-left: -57%;

	.mat-menu-item {
		height: auto;
		min-height: 30px;
		font-size: 12px;
		line-height: 1;
		color: var(--orange1);
	}
}

/* popup */
.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.7);
}

/* container--box  */
.container--box {
	border: 0.5px solid var(--grey6);
	box-sizing: border-box;
	border-radius: 5px;
}

/* helptext__box  */
.helptext__box {
	position: relative;
	background: var(--grey4);
	border-radius: 4px;
	padding: 20px 20px;
	margin-top: 30px;

	p {
		margin: 0;
		position: relative;
		padding-left: 40px;
		color: var(--grey2);
		font-weight: 400;

		svg {
			position: absolute;
			top: -3px;
			left: 0;
		}

		span {
			font-weight: 500;
		}
	}

	a {
		text-decoration: none;
		color: var(--orange1);
	}
}

/* table */
.table-border {
	border: 1px solid var(--grey6);
	width: 100%;

	.mat-header-row {
		min-height: 40px;
		height: auto;
		background-color: var(--grey4);

		th {
			padding: 10px;
			text-align: left;
			font-weight: 400;
			vertical-align: middle;

			&:first-child {
				padding-left: 20px !important;
			}
		}
	}

	td {
		padding: 10px !important;
		text-align: left;
		font-weight: 400;
		vertical-align: middle;

		&:first-child {
			padding-left: 20px !important;
		}
	}

	.mat-header-cell,
	mat-cell {
		color: var(--grey1);
		font-size: 14px;
	}

	mat-row {
		&:last-child {
			border-bottom: none;
		}
	}

	mat-icon {
		color: var(--red1);
	}
}

.tracked-table {
	text-align: center;
	color: var(--grey3);
	border: 1px dashed var(--grey3);
	padding: 15px;
}

.track-table {
	color: var(--orange1);
	text-decoration: none;
	cursor: pointer;
	margin-right: 15px;
}

.add-track {
	color: var(--red1);
	text-decoration: none;
}

/* button-groups */
.button-groups {
	button {
		&:last-child {
			margin-left: 30px;
		}
	}
}

.button-group-new {
	button {
		// height: 50px !important;

		&:last-child {
			margin-left: 30px;
		}
	}
}

.add-btn {
	width: 80px;
	height: 32px !important;
}

.create-btn {
	width: 128px;
	height: 32px !important;
	position: relative;
	z-index: 3;

	&.border-btn {
		background: transparent;
		border: 1px solid var(--orange1);
		color: var(--orange1);
	}
}

/* pagination */
.pagination {
	display: inline-block;
	border: solid var(--grey10);

	a {
		color: var(--black);
		padding: 5px 5px;
		text-decoration: none;
		border: 1px solid var(--grey10);

		&.active {
			background-color: var(--green1);
			color: var(--white);
			border-radius: 5px;
		}

		&:hover:not(.active) {
			background-color: var(--grey10);
			border-radius: 5px;
		}
	}
}

/* row--example */
.row--example {
	.table {
		border: 0.3px solid var(--grey6);
		font-size: 14px;
		line-height: 24px;

		&> :not(caption)>*>* {
			padding: 0px 0px 0px 10px;
		}

		tr:first-child {

			th,
			td {
				padding-top: 15px !important;
			}
		}

		tr:nth-child(2) {
			td {
				background: var(--grey5);
			}
		}

		th {
			background: var(--grey7);
			width: 26px;
			padding: 0;
			text-align: center;
			color: var(--grey3);
		}
	}
}

/*search  */
.search-icon {
	position: absolute;
	top: 13px;
	right: 0;
	color: var(--grey6);
	background: url("/assets/images/icons/search-icon.svg") no-repeat center center;
	text-indent: -999px;
	overflow: hidden;

	::ng-deep .mat-select-arrow {
		color: inherit !important;
	}
}

.search-icon-grey {
	position: absolute;
	top: 13px;
	right: 0;
	color: var(--grey6);
	background: url("/assets/images/icons/search-icon-grey.svg") no-repeat center center;
	text-indent: -999px;
	overflow: hidden;

	::ng-deep .mat-select-arrow {
		color: inherit !important;
	}
}

/* connection  */
.connection-header {
	.mat-stroked-button {
		.mat-button-wrapper {
			color: var(--orange1);
		}
	}

	.status-icon {
		width: 15px;
		height: 15px;
		border-radius: 25px;
		margin: 3px;
		margin-right: 10px;
	}

	.no-connection {
		background-color: var(--red2);
	}

	.connection-status {
		background-color: var(--green2);
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: inline-flex;

		li {
			margin: 0 9px;
		}
	}

	@media screen and (max-width: 1200px) {
		.w100 {
			width: auto !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	@media screen and (max-width: 991px) {
		.w100 {
			width: 100% !important;
		}

		.text-center {
			text-align: left;
		}
	}
}

.no-connection {
	padding: 30px 70px 35px;

	.status-icon {
		width: 15px;
		height: 15px;
		border-radius: 25px;
		margin: 2px;
		margin-right: 10px;
	}

	.no-connection-status {
		background-color: var(--red2);
	}

	.button-wrapper {
		padding-bottom: 4rem;

		button {
			width: 148px;
			margin: 15px;
		}
	}

	.no-connection-box {
		padding: 30px;
		height: 480px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h5 {
			margin: 25px 0 10px 0;
		}
	}

	@media screen and (max-width: 991px) {
		.sm-display-block {
			display: block !important;
		}

		.text-center {
			text-align: left;
		}
	}

	@media screen and (max-width: 991px) {
		padding: 40px 10px;
	}
}

/* overview common */
.overview-link-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 20px;

	.link {
		color: var(--orange1);
		cursor: pointer;

		b {
			font-weight: 600;
		}
	}

	.back-link {
		color: var(--orange1);
		background: url("/assets/images/icons/back-arrow.svg") no-repeat left center;
		padding-left: 22px;
		cursor: pointer;
	}

	.dropdown-list {
		position: relative;
		padding-left: 15px;

		@media screen and (max-width: 1440px) {
			max-width: 170px;
		}

		&::before {
			content: "";
			position: absolute;
			width: 1px;
			height: 26px;
			border-left: 0.3px solid var(--grey6);
			left: 0;
			bottom: 6px;
		}

		.mat-form-field-type-mat-select,
		.mat-form-field-type-mat-native-select {
			&.mat-form-field-appearance-outline {

				.mat-form-field-outline,
				.mat-form-field-infix,
				.mat-form-field-wrapper,
				.mat-input-element {
					height: 32px;
				}

				.mat-form-field-infix {
					bottom: 6px;
				}
			}
		}
	}

	>div {
		// margin-right: 20px;
		margin: 0 10px;

		&:last-child {
			margin-right: 0;
		}

		// @media screen and (max-width: 1440px) {
		// 	margin-right: 15px;
		// }
	}

	@media screen and (max-width: 991px) {
		.dropdown-list {
			&::before {
				bottom: 8px;
			}
		}

		>div {
			margin-top: 10px;
		}
	}
}

.search-table {
	position: relative;
	padding: 8px 10px;

	input {
		width: calc(100% - 30px);
		height: 32px;
		padding: 0 15px;
		border: 0.3px solid var(--grey6);
		border-radius: 2px;
	}

	.mat-icon {
		right: 13px;
	}
}

/* .mat-dialog-container */
.mat-dialog-container {
	padding: 0 !important;
	overflow: hidden !important;
}

/* popup-section */
.cdk-overlay-pane {
	// width: 35vw !important;
	// justify-content: right;
}
.mat-select-panel-wrap {
    flex-basis: initial !important;
	width: 100% !important;
}
// .mat-select-panel-wrap {
// 	flex-basis: inherit !important;
//     width: calc(100% - 45%) !important;
// }

.popup-section {
	.popup-header {
		position: relative;
		background-color: var(--white);
		color: var(--grey1);
		text-align: left;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		padding: 12px 16px;
		width: 100%;

		.close-icon {
			color: var(--grey1);
			cursor: pointer;
			position: absolute;
			right: 20px;
		}
	}

	.popup-body {
		padding: 24px;
		border-top: 1px solid var(--grey6);
		border-bottom: 1px solid var(--grey6);

		p {
			font-size: 14px;
			color: var(--grey1);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.popup-footer {
		padding: 16px;

		.button-groups {
			button {
				width: 90px;
				height: 32px;
				vertical-align: middle;
				line-height: 1;

				&:last-child {
					margin-left: 16px;
				}
			}
		}
	}
}

/* sidenav-popup */
.sidenav-popup {
	height: 100%;
	right: 0;
	max-width: 562px;
	width: 100%;

	.sidenav-popup-header {
		border-bottom: 1px solid var(--grey6);
		padding: 8px 20px 8px 30px;
		position: relative;

		p {
			margin: 0;
		}

		.mat-icon {
			position: absolute;
			top: 6px;
			right: 10px;
			color: var(--orange1);
			cursor: pointer;
		}
	}

	.sidenav-popup-body {
		padding: 30px;
		min-height: calc(100% - 100px);
	}

	.sidenav-popup-footer {
		text-align: right;
		padding: 0 30px;
	}
}

.f600 {
	font-weight: 600;
}

/*scrollbar*/
/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--orange1);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--orange1);
}

.mandatory {
	color: red;
}
.cPointer {
	cursor: pointer;
}
.icon-style {
	width: 27px;
	// height: 27px;
	padding: 5px;
}

.minH493 {
	min-height: 493px;
}

::ng-deep {
	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background-color: #FF341D !important;
	}

	.mat-checkbox-background {
		background-color: #FF341D !important;
	}

	.mat-primary .mat-pseudo-checkbox-checked {
		background-color: #FF341D !important;
	}

	.mat-radio-button.mat-accent .mat-radio-inner-circle {
		background-color: var(--orange1) !important;
	}

	.mat-tab-group.mat-primary .mat-ink-bar,
	.mat-tab-nav-bar.mat-primary .mat-ink-bar {
		background-color: #FF341D !important;
	}

}

// .wrapper-table {
// 	::ng-deep {
// 		.tracked-table-data {
// 			margin: 0;
// 			list-style-type: none;
// 			border: 1px solid var(--grey8);
// 			padding: 5px 10px;
// 			background-color: var(--grey4);

// 			&.mat-header-row {
// 				padding: 0;
// 				border: none;
// 				height: 40px !important;
// 			}
// 		}
// 	}
// }

::ng-deep {
	.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
		background-color: #ff341d !important;
	}

}

/*global*/
.form-label {
	p {
		margin: 0 0 4px !important;
	}
//changes done on 05/02/2024
	// .mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
	// 	min-height: 48px;
	// }

	.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-infix {
		padding: none !important;
	}
}



.form-label-btn {
	button {
		height: 48px !important;
	}
}

.project-style {
	.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
		min-height: 32px !important;
	}
}

.darkgrey-outline {
	.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
		border: 1px solid #8692A6 !important;
	}

	.mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-form-field-outline {
		border: 1px solid #8692A6 !important;
		border-radius: 5px;
	}
}

.mat-radio-label-content {
	padding-left: 12px !important;
}

.action-btn-style {
	img {
		width: 18px;
		height: 18px;
	}
}

.wrapper-pl {
	input::placeholder {
		font-size: 14px;
		font-weight: 400;
		padding-left: 30px;
	}
}

.user-search {
	.icon {
		position: absolute;
		left: 20px;
		top: -3px;
		margin-bottom: 5px;

	}

	input {
		background-image: url("../../images/icons/bi_search.svg");
		// background-image: url(../../../../../assets/images/icons/bi_search.svg);
		background-repeat: no-repeat;
		background-size: 15px;

	}

	.no-back {
		background-image: none;
	}

	input::placeholder {
		padding-left: 20px;
	}

	::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
		display: flex !important;
		flex-direction: row-reverse;

		.search-icon {
			color: #666666;
		}
	}

	.mat-form-field-type-mat-input.mat-form-field-appearance-outline {
		width: 337px;
		border-radius: 4px;
	}
}

button.mat-primary {
	height: 32px !important;
	min-width: 120px !important;
}

.ewrapper {
	height: 100%;
}

.searchBox1 {
	display: flex;
	padding: 0 10px;
	color: transparent;
	min-height: 36px !important;
	border: 1px solid var(--grey14);
	border-radius: 4px;
	width: 337px !important;

	input {
		//   border: none;
		width: 100%;
		margin-left: 10px;
		color: #999999;
		font-size: 14px;
	}

	input:focus {
		outline: none;
	}
}

.aStyle {
	.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
		height: 32px !important;
		min-height: inherit !important;
		max-width: 440px !important;
		width: 100% !important;
	}

	.mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-form-field-outline {
		height: 32px !important;
		min-height: inherit !important;
		max-width: 280px !important;
		width: 100% !important;
	}

	.mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-form-field-infix {
		padding-top: 9px !important;
	}

	.mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
		margin-right: 10px !important;
	}

	.mat-form-field-appearance-outline .mat-form-field-infix {
		padding: 9px 0 10px !important;
	}
}

.addMore {
	button {
		font-size: 10px;
	}

	button.mat-primary {
		height: 26px !important;
		width: 84px !important;
	}
}


//tabel border
thead, tbody, tfoot, tr, td, th {
    border-color: #C4C4C4!important;
}
//popup
.mat-dialog-container{
	background: white;
}
//mat select
.mat-select-panel{
	width: 100%;
	background: white;
}
.mat-option.mat-active {
    background: rgba(0,0,0,.04);
    color: #000000de;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0,0,0,.04);
}
.mat-option {  
    border: 1px solid #d9d9d9;
}
.dropdownstyle {
	cursor: pointer;
	img {
		position: absolute;
		right: 0;
	}	
}
