@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

html,
body {
	height: 100%;
	position: relative;
	margin: 0;
	font-family: "Poppins", sans-serif !important;
	font-size: 14px;
	line-height: 20px;
	overflow: hidden;
	color: var(--grey1);
	font-weight: 400;
}

* {
	font-family: "Poppins", sans-serif !important;
}

.material-icons {
	font-family: "Material Icons" !important;
}

/* typography */
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--black);
	font-weight: 500;
}

/* font-size */
.f-10 {
	font-size: 10px !important;
}
.f-12 {
	font-size: 12px !important;
}

.f-13 {
	font-size: 13px !important;
}

.f-14 {
	font-size: 14px !important;
}

.f-16 {
	font-size: 16px !important;
}

.f-18 {
	font-size: 18px !important;
}
.f-20 {
	font-size: 20px !important;
}

.f-22 {
	font-size: 22px !important;
}

.f-24 {
	font-size: 24px !important;
}

.f-32 {
	font-size: 32px !important;
}

/* font-weight */
.fw-100 {
	font-weight: 100;
}
.fw-200 {
	font-weight: 200;
}
.fw-300 {
	font-weight: 300;
}
.fw-400 {
	font-weight: 400 !important;
}
.fw-500 {
	font-weight: 500 !important;
}
.fw-600 {
	font-weight: 600 !important;
}
.fw-700 {
	font-weight: 700;
}
.fw-800 {
	font-weight: 800;
}
.fw-900 {
	font-weight: 900;
}
