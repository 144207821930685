:root {
	--white: #ffffff;
	--black: #000;
	--grey: #d9d9d9;
	--grey1: #333333;
	--grey2: #666666;
	--grey3: #999999;
	--grey4: #fbede3;
	--grey5: #fafafa;
	--grey6: #c4c4c4;
	--grey7: #f7f7f7;
	--grey8: #ccc;
	--grey9: #b7bab6;
	--grey10: #ddd;
	--grey11: #e1e1e1;
	--grey12: #dee2e6;
	--grey13: #797979;
	--grey14: #d9d9d9;
	--grey15: #060403;
	--grey16: #141414;

	--lightgrey1: #f0f0f0;

	--orange1: #ff341d;
	--red1: #f44336;
	--red2: #f5222d;
	--green1: #4caf50;
	--green2: #34ff34;
	--blue1: #046582;
	--blue2: #0a58ca;
	--maroon1: #b11a04;
	--maroon2: #ec5992;
	--purple: purple;
	--dblack: #060403;
}
