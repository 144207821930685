@import "@angular/material/theming";
@import "assets/styles/base/variables";
@import "assets/styles/base/reset";
@import "assets/styles/base/typography";
@import "assets/styles/base/utility";
@import "assets/styles/base/common";
@import "assets/styles/components/buttons";
@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';
@import "codemirror/addon/fold/foldgutter.css";
@import "codemirror/addon/hint/show-hint.css";


.CodeMirror-foldmarker {
    color: black !important;
    text-shadow: none !important;
    font-family: arial;
    line-height: .3;
    cursor: pointer;
}
::ng-deep {
	.icon-style {
		img {
			cursor: pointer !important;
		}
	}
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
