/* font-size */
.f-12 {
	font-size: 12px !important;
}

.f-21 {
	font-size: 21px;
}

/* text color  */
.text-blue {
	color: var(--blue1);
}

.text-dark-blue {
	color: var(--blue2);
}

.text-darkgrey {
	color: var(--grey1);
}

.text-grey {
	color: var(--grey2);
}

.text-grey3 {
	color: var(--grey3);
}
.text-white {
	color: var(--white);
}

.bcolor {
	color: var(--black);
}

.dblackcolor {
	color: var(--dblack);
}

.text-orange {
	color: var(--orange1);
}

.error {
	color: var(--red1);
}

/*  bg */
.bg-grey {
	background: var(--grey9);
}
.bg-white {
	background: var(--white);
}

.lightgrey-bg {
	background: var(--grey5);
}

.lightpink-bg {
	background: var(--grey4);
}

/* width */
.auto-width {
	width: auto !important;
}

.w50 {
	width: 50px;
}

.w80 {
	width: 80px;
}

.w85 {
	width: 85px;
}

.w95 {
	width: 95px;
}

.w100 {
	width: 100px;
}

.w120 {
	width: 120px;
}

.w150 {
	width: 150px;
}

.w-5 {
	width: 5%;
}

.w-10 {
	width: 10%;
}

.w-20 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}

.w-100 {
	width: 100%;
}

.display-grid {
	display: grid;
}

/* text align */
.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center !important;
}

.text-end {
	text-align: end;
}

.pd25 {
	padding: 25px !important;
}

@media screen and (max-width: 991px) {
	.sm-text-left {
		text-align: left;
	}

	.sm-text-right {
		text-align: right;
	}

	.sm-text-center {
		text-align: center;
	}
}

/* text-decoration */
.text-decoration-no {
	text-decoration: none;
}

.text-decoration-underline {
	text-decoration: underline;
}

/* padding */
.pt-0 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-30 {
	padding-top: 30px !important;
}

.pt-40 {
	padding-top: 40px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-70 {
	padding-top: 70px;
}

.pt-80 {
	padding-top: 80px;
}

.pt-90 {
	padding-top: 90px;
}

.pt-100 {
	padding-top: 100px;
}

.pb-0 {
	padding-bottom: 0;
}

.pb-10 {
	padding-bottom: 30px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pd-30 {
	padding-bottom: 30px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pd-0 {
	padding: 0;
}

.pd-10 {
	padding: 10px;
}

.pd-30 {
	padding: 30px;
}

.pl-0 {
	padding-left: 0;
}

.pl-5 {
	padding-left: 5px;
}

.pl-8 {
	padding-left: 8px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px !important;
}

.pl-25 {
	padding-left: 25px;
}

.pl-30 {
	padding-left: 30px;
}

.pr-0 {
	padding-right: 0;
}

.pr-25 {
	padding-right: 25px;
}

.ptb-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.plr-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.pl5 {
	padding-left: 5%;
}

@media screen and (max-width: 991px) {
	.sm-pt-10 {
		padding-top: 10px;
	}

	.sm-pt-20 {
		padding-top: 20px;
	}

	.sm-pt-30 {
		padding-top: 30px;
	}

	.sm-pt-40 {
		padding-top: 40px;
	}

	.sm-pt-50 {
		padding-top: 50px;
	}

	.sm-pt-60 {
		padding-top: 60px;
	}

	.sm-pt-70 {
		padding-top: 70px;
	}

	.sm-pt-80 {
		padding-top: 80px;
	}

	.sm-pt-90 {
		padding-top: 90px;
	}

	.sm-pt-100 {
		padding-top: 100px;
	}

	.sm-pb-10 {
		padding-bottom: 10px;
	}

	.sm-pb-10 {
		padding-bottom: 20px;
	}

	.sm-pd-30 {
		padding-bottom: 30px;
	}

	.sm-pb-40 {
		padding-bottom: 40px;
	}

	.sm-pb-50 {
		padding-bottom: 50px;
	}

	.sm-pb-60 {
		padding-bottom: 60px;
	}

	.sm-pb-70 {
		padding-bottom: 70px;
	}

	.sm-pb-80 {
		padding-bottom: 80px;
	}

	.sm-pb-90 {
		padding-bottom: 90px;
	}

	.sm-pb-100 {
		padding-bottom: 100px;
	}
}

/* margin */
.no-margin {
	margin: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-05 {
	margin-top: 5px !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-45 {
	margin-top: 45px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mt-55 {
	margin-top: 55px !important;
}

.mt-60 {
	margin-top: 60px !important;
}

.mt-70 {
	margin-top: 70px !important;
}

.mt-80 {
	margin-top: 80px !important;
}

.mt-90 {
	margin-top: 90px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-05 {
	margin-bottom: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-35 {
	margin-bottom: 35px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-45 {
	margin-bottom: 45px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}

.mb-70 {
	margin-bottom: 70px !important;
}

.mb-80 {
	margin-bottom: 80px !important;
}

.mb-90 {
	margin-bottom: 90px !important;
}

.mb-100 {
	margin-bottom: 100px !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.ml-5 {
	margin-left: 5px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-15 {
	margin-left: 15px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.ml-25 {
	margin-left: 25px !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mr-25 {
	margin-right: 25px !important;
}

.mr-30 {
	margin-right: 30px !important;
}

.mlr-15 {
	margin-left: 15px !important;
	margin-right: 15px !important;
}

.mlr-20 {
	margin-left: 20px !important;
	margin-right: 20px !important;
}

.ml-auto {
	margin-left: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

@media screen and (max-width: 1199px) {
	.mt-0 {
		margin-top: 0 !important;
	}

	.md-mt-10 {
		margin-top: 10px !important;
	}

	.md-mt-20 {
		margin-top: 20px !important;
	}

	.md-mt-30 {
		margin-top: 30px !important;
	}

	.md-mt-40 {
		margin-top: 40px !important;
	}

	.md-mt-50 {
		margin-top: 50px !important;
	}

	.md-mt-60 {
		margin-top: 60px !important;
	}

	.md-mt-70 {
		margin-top: 70px !important;
	}

	.md-mt-80 {
		margin-top: 80px !important;
	}

	.md-mt-90 {
		margin-top: 90px !important;
	}

	.md-mt-100 {
		margin-top: 100px !important;
	}

	.md-mb-10 {
		margin-bottom: 10px !important;
	}

	.md-mb-10 {
		margin-bottom: 20px !important;
	}

	.md-mb-30 {
		margin-bottom: 30px !important;
	}

	.md-mb-40 {
		margin-bottom: 40px !important;
	}

	.md-mb-50 {
		margin-bottom: 50px !important;
	}

	.md-mb-60 {
		margin-bottom: 60px !important;
	}

	.md-mb-70 {
		margin-bottom: 70px !important;
	}

	.md-mb-80 {
		margin-bottom: 80px !important;
	}

	.md-mb-90 {
		margin-bottom: 90px !important;
	}

	.md-mb-100 {
		margin-bottom: 100px !important;
	}

	.md-ml-0 {
		margin-left: 0 !important;
	}

	.md-ml-10 {
		margin-left: 10px !important;
	}

	.md-ml-15 {
		margin-left: 15px !important;
	}

	.md-ml-20 {
		margin-left: 20px !important;
	}

	.md-mr-0 {
		margin-right: 0 !important;
	}

	.md-mr-10 {
		margin-right: 10px !important;
	}

	.md-mr-15 {
		margin-right: 15px !important;
	}

	.md-mr-20 {
		margin-right: 20px !important;
	}
}

@media screen and (max-width: 767px) {
	.sm-mt-0 {
		margin-top: 0 !important;
	}

	.sm-mt-10 {
		margin-top: 10px !important;
	}

	.sm-mt-20 {
		margin-top: 20px !important;
	}

	.sm-mt-30 {
		margin-top: 30px !important;
	}

	.sm-mt-40 {
		margin-top: 40px !important;
	}

	.sm-mt-50 {
		margin-top: 50px !important;
	}

	.sm-mt-60 {
		margin-top: 60px !important;
	}

	.sm-mt-70 {
		margin-top: 70px !important;
	}

	.sm-mt-80 {
		margin-top: 80px !important;
	}

	.sm-mt-90 {
		margin-top: 90px !important;
	}

	.sm-mt-100 {
		margin-top: 100px !important;
	}

	.sm-mb-10 {
		margin-bottom: 10px !important;
	}

	.sm-mb-10 {
		margin-bottom: 20px !important;
	}

	.sm-mb-30 {
		margin-bottom: 30px !important;
	}

	.sm-mb-40 {
		margin-bottom: 40px !important;
	}

	.sm-mb-50 {
		margin-bottom: 50px !important;
	}

	.sm-mb-60 {
		margin-bottom: 60px !important;
	}

	.sm-mb-70 {
		margin-bottom: 70px !important;
	}

	.sm-mb-80 {
		margin-bottom: 80px !important;
	}

	.sm-mb-90 {
		margin-bottom: 90px !important;
	}

	.sm-mb-100 {
		margin-bottom: 100px !important;
	}

	.sm-ml-0 {
		margin-left: 0 !important;
	}

	.sm-ml-10 {
		margin-left: 10px !important;
	}

	.sm-ml-15 {
		margin-left: 15px !important;
	}

	.sm-ml-20 {
		margin-left: 20px !important;
	}

	.sm-mr-0 {
		margin-right: 0 !important;
	}

	.sm-mr-10 {
		margin-right: 10px !important;
	}

	.sm-mr-15 {
		margin-right: 15px !important;
	}

	.sm-mr-20 {
		margin-right: 20px !important;
	}
}

/* flex box in mobile */
.align-baseline {
	align-items: baseline;
}

.align-item-center {
	align-items: center;
}

.justify-end {
	justify-content: end;
}

.justify-center {
	justify-content: center !important;
}


@media screen and (max-width: 1199px) {
	.md-justify-content {
		justify-content: center;
	}
}

@media screen and (max-width: 991px) {
	.sm-justify-content {
		justify-content: center;
	}
}

/* position */
.position-relative {
	position: relative;
}

/* border-top */
body {
	.border-top {
		border-top: 1px solid var(--grey4) !important;
	}

	.border-bottom {
		border-bottom: 1px solid var(--grey4) !important;
	}

	.border-left {
		border-left: 1px solid var(--grey4) !important;
	}

	.border-right {
		border-right: 1px solid var(--grey4) !important;
	}
}

/* hidden */
.hidden {
	overflow: hidden !important;
}

/* resposive class */
@media screen and (max-width: 991px) {
	.sm-display-block {
		display: block !important;
	}

	.sm-width-100 {
		width: 100%;
	}

	.sm-pd-25 {
		padding: 25px;
	}
}

/* other */
.cursor-pointer {
	cursor: pointer;
}

.border-right {
	border-right: 1px solid var(--grey12);
}

.border-radius-5 {
	border-radius: 5px;
}

.rpdl {
	padding-left: 0 !important;
}